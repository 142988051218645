body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.BotChat {
   background-color: #ff0077;
   padding: 15px;
   color: white;
   font-size: 20px;
   margin-top: 10px;
}
.UserChat {
  background-color: #0000ff;
  padding: 15px;
  color: white;
  font-size: 20px;
  margin-top: 10px;
}
.UserName {
   font-size: 24px;
}
.AnswerBox {
  position: fixed;
   bottom: 0;
   margin-bottom: 20px;
   margin-left: 8px;
   width: 100%;
}
input {
  padding: 15px;
  font-size: 20px;
  border: none;
  outline: none;
  border-radius: 360px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: #ff0077 solid 1px;
  width: max-content;
}
.profile {
  border-radius: 360px;
}
button {
  background-color: #ff0077;
  color: white;
  border-radius: 10px;
  font-size: 20px;
  padding: 15px;
  border: none;
  outline: none;
  width: 12.5em;
}
img {
  border-radius: 10px;
}